body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* CRA BOILERPLATE */
/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* CRA BOILERPLATE END */

#container {
  font-family: "Lato", serif;
  max-width: 1100px;
  min-width: 350px;
  margin: auto;
}

#nav-bar {
  background-color: #ffffff;
  display: flex;
  padding: 0px 41px;
}

#header-img {
  width: 139px;
  height: 50px;
}

#nav-text {
  display: inline;
  position: absolute;
  top: 31%;
  right: 20px;
}

.nav-link {
  padding: 16px;
  color: #555555;
  font-size: 16px;
  font-weight: 600;
}

.menubtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

#content {
  display: block;
  padding: 40px;
}

#me {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 39px 0px 0px 0px;
}

#pro-img {
  margin: auto;
  width: 200px;
  border-radius: 100px;
}

#name_code {
  font-family: monospace;
  font-size: 48px;
  font-weight: 800;
  color: #3f6ca5;
  margin-top: 10px;
}

#tagline {
  font-family: monospace;
  font-size: 34px;
  font-weight: 800;
  margin: 0px 27px;
  text-align: center;
}

#s1 {
  max-width: 700px;
  margin: 42px auto;
}

#f1,
#f2,
#f3,
#f4,
#f5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 17px 0px;
}

.icon {
  display: flex;
  justify-content: center;
  font-size: 50px;
  width: 74px;
  padding: 0px 48px 0px 0px;
  color: #4f83c4;
}

.icon_mobile {
  font-size: 50px;
  width: 64px;
  margin: auto;
  color: #4f83c4;
  display: none;
}

.feat-head {
  font-size: 25px;
  font-weight: 600;
}

.feat-text {
  font-size: 17px;
}

#s2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

#offers-all {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.section-head,
#portfolio {
  text-align: center;
  margin: 37px 0px -34px 0px;
}

.offers {
  /* border: 2px solid #4f83c4; */
  /* background-color: #00000013; */
  border-radius: 5px;
  text-align: center;
  margin: 42px 0px;
  width: 26vw;
  max-width: 310px;
  min-width: 245px;
  box-shadow: 2px 2px 14px rgba(156, 156, 156, 0.36);
  overflow: hidden;
  box-sizing: border-box;
}

.offer-head {
  background-color: #4f83c4;
  font-size: 22px;
  line-height: 48px;
  box-shadow: 0px 1px 3px rgba(156, 156, 156, 1);
  color: #f0f8ff;
}

.offer-price {
  font-weight: 600;
  font-size: 22px;
  margin: 13px 0px;
}

.offer-text {
  margin: 15px 0px 28px 0px;
  font-size: 18px;
}

.offer-line {
  margin: 15px auto;
}

#portfolio {
  margin: -16px 0px -53px 0px;
}

#projects-all {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-end;
}

.projects {
  text-align: center;
  margin: 43px 0px;
  width: 26vw;
  max-width: 310px;
  min-width: 245px;
  overflow: hidden;
}

.project-head {
  font-size: 22px;
  line-height: 31px;
  margin: 9px 0px 14px 0px;
}

.proj-img {
  max-width: 90%;
  border-radius: 100%;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

a img:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.35);
}

#contact-head {
  margin: 0px 0px 10px 0px;
  text-align: center;
}

#social-all {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 450px;
  min-width: 285px;
  margin: auto;
}

.social {
  font-size: 50px;
  width: 64px;
  color: #4f83c4;
}

/*STACK OFFERS*/
@media only screen and (max-width: 925px) {
  #offers-all,
  #projects-all {
    flex-direction: column;
    margin: 47px 0px;
  }

  .offers {
    margin: 28px auto;
    width: 270px;
  }

  .projects {
    margin: 28px auto;
    width: 270px;
  }

  #services {
    margin: 48px 0px -51px 0px;
  }

  #portfolio {
    margin: 0px 0px -60px 0px;
  }
}

/*HIDE ICONS*/
@media only screen and (max-width: 500px) {
  .icon {
    display: none;
  }

  .icon_mobile {
    display: block;
  }

  .feat-copy {
    text-align: center;
  }
}

/*STACK NAVBAR*/
@media only screen and (max-width: 590px) {
  #nav-bar {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    /* height: 169px; */
  }

  #header-img {
    display: block;
    margin: 0px auto;
    z-index: 5;
    background-color: #ffffff;
  }
  .menubtn{
    z-index: 50;
  }
  #nav-text {
    /* position: relative; */
    position: static;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    /* margin: -3px auto 5px auto; */
    /* margin: -110px auto; */
    width: 100%;
    z-index: 1;
    top: -10px;
    /* line-height: 10px; */
    /* top: -50px; */
    background-color: rgba(255, 255, 255, 1);
  }
  
  .visible {
    /* width: 100%; */
    margin: -13px auto 0px auto;
    /* margin: -150px auto; */
    padding: 8px;
  }
  
  .toohigh {
    margin: -140px auto 5px auto;
    /* padding: 8px; */
  }

  .transform {  
    transition: all 300ms ease;
  }

  .transform-active {
    /* background-color: rgba(255, 255, 255, 1); */
    margin: -13px auto 0px auto;
    /* padding: 8px; */
  }

  .nav-link {
    padding: 12px;
    color: #555555;
    line-height: 9px;
  }

  #social-all {
    width: 270px;
  }

  .social {
    font-size: 2.8em;
  }


  /* #content {
    margin: 98px 0px 0px 0px;
  } */
}

